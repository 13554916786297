.cta-btn .p-button {
  position: relative;
  display: block;
  background: #fff;
  border: 0;
  // box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .15);
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  color: rgba(46, 59, 80, .6);
  font-size: 18px;
  border-radius: 5px;
  text-decoration: none;
  transition: all .2s ease-in-out;
  border: 1px solid var(--deep-lavender);
  color: var(--deep-lavender);

  &:hover {
    background: #8f63de;
    color: #fff;

    strong {
      color: #fff !important;
    }
  }

  strong {
    color: #8f63de;
  }

  &:hover strong {
    color: #fff;
  }

  i {
    position: absolute;
    right: 30px;
    top: 25px;
    height: 15px;
  }
}


.btn-soft-primary {
  background-color: #556ee61a;
  border-color: #0000;
  color: var(--deep-lavender-dark);
  transition: all .2s ease
}

.btn-soft-primary:active,
.btn-soft-primary:focus,
.btn-soft-primary:hover {
  background-color: var(--deep-lavender-dark);
  border-color: #0000;
  color: #fff
}

.btn-soft-secondary {
  background-color: #74788d1a;
  border-color: #0000;
  color: #74788d;
  transition: all .2s ease
}

.btn-soft-secondary:active,
.btn-soft-secondary:focus,
.btn-soft-secondary:hover {
  background-color: #74788d;
  border-color: #0000;
  color: #fff
}

.btn-soft-success {
  background-color: #34c38f1a;
  border-color: #0000;
  color: #1D6D4F;
  transition: all .2s ease
}

.btn-soft-success:active,
.btn-soft-success:focus,
.btn-soft-success:hover {
  background-color: #217D5B;
  border-color: #0000;
  color: #fff
}

.btn-soft-info {
  background-color: #50a5f11a;
  border-color: #0000;
  color: #0F6CBD;
  transition: all .2s ease
}

.btn-soft-info:active,
.btn-soft-info:focus,
.btn-soft-info:hover {
  background-color: #50a5f1;
  border-color: #0000;
  color: #fff
}

.btn-soft-warning {
  background-color: #f1b44c1a;
  border-color: #0000;
  color: #f1b44c;
  transition: all .2s ease
}

.btn-soft-warning:active,
.btn-soft-warning:focus,
.btn-soft-warning:hover {
  background-color: #f1b44c;
  border-color: #0000;
  color: #fff
}

.btn-soft-danger {
  background-color: #f46a6a1a;
  border-color: #0000;
  color: #f46a6a;
  transition: all .2s ease
}

.btn-soft-danger:active,
.btn-soft-danger:focus,
.btn-soft-danger:hover {
  background-color: #f46a6a;
  border-color: #0000;
  color: #fff
}

.btn-soft-pink {
  background-color: #e83e8c1a;
  border-color: #0000;
  color: #e83e8c;
  transition: all .2s ease
}

.btn-soft-pink:active,
.btn-soft-pink:focus,
.btn-soft-pink:hover {
  background-color: #e83e8c;
  border-color: #0000;
  color: #fff
}

.btn-soft-light {
  background-color: #eff2f71a;
  border-color: #0000;
  color: #eff2f7;
  transition: all .2s ease
}

.btn-soft-light:active,
.btn-soft-light:focus,
.btn-soft-light:hover {
  background-color: #eff2f7;
  border-color: #0000;
  color: #fff
}

.btn-soft-dark {
  background-color: #343a401a;
  border-color: #0000;
  color: #343a40;
  transition: all .2s ease
}

.btn-soft-dark:active,
.btn-soft-dark:focus,
.btn-soft-dark:hover {
  background-color: #343a40;
  border-color: #0000;
  color: #fff
}