@import 'fluid';

// e.g
// .outer-box {
//     @include aspect-ratio(4, 3);
//  }
@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
       display: block;
       content: "";
       width: 100%;
       padding-top: ($height / $width) * 100%;
    }
    > .inner-box {
       position: absolute;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
    }
 }

 $sm: 576px !default;
 $md: 768px !default;
 $lg: 992px !default;
 $xl: 1200px !default;
 $xxl: 1400px !default;
 $ml: 1800px !default;
 $qhd: 2560px !default;
 $_2k: 2048px !default; 
 $gutter: .5rem !default;
 
 $fieldMargin: 1rem !default;
 $fieldLabelMargin: .5rem !default;
 $helperTextMargin: .25rem !default;
 
 $spacer: 1rem !default;
 
 $breakpoints: (
     'sm': $sm,
     'md': $md,
     'lg': $lg,
     'xl': $xl,
     'xxl': $xxl,
     'qhd': $qhd,
     '2k': $_2k,
 ) !default;
// e.g
// @include breakpoint(large) {
//     div {
//        font-size: 2rem;
//        line-height: 1.4;
//     }
//  }
 
@mixin breakpoint($point) {

    @if $point == qhd {
      @media only screen and (min-width: $xl) and (max-width: $qhd) {
          @content;
      }
    }
    @if $point == _2k {
      @media only screen and (min-width: $_2k) {
          @content;
      }
    }
    @if $point == xxlarge {
      @media only screen and (min-width: $xxl){
          @content;
      }
    }
    @if $point ==large {
        @media only screen and (min-width: $xl) {
            @content;
        }
    }

    @else if $point ==desktop {
        @media only screen and (min-width: $lg) {
            @content;
        }
    }

    @else if $point ==small-laptop {
        @media only screen and (max-width: $lg)  and (max-height: $lg) {
            @content;
        }
    }

    @else if $point ==laptop {
        @media only screen and (min-width:  $md) {
            @content;
        }
    }

    @else if $point ==small-height-laptop {
        @media only screen and (min-width: $md)  and (max-height: $md) {
            @content;
        }
    }

    @else if $point ==tablet {
        @media only screen and (min-width: $sm) {
            @content;
        }
    }
    @else if $point ==mobile {
      @media only screen and (max-width: 768px) {
          @content;
      }
    }
}

// e.g @include font-size(14px)
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: calculateRem($size);
}


@mixin grids($grids) {
    // SETUP
    $total-columns: 12;
    $breakpoints: (xxs:320px, xs:480px, sm:768px, md:992px, lg:1200px);
    $gutter: 1%;
    
    // Width of one column
    $unit-width: (100% - $gutter * 2 * ($total-columns - 1)) / $total-columns;
    
    @each $sel, $sizes in $grids
    {
      // Clear fix
      #{$sel}:after { display: table; content: " "; clear:both; }
     
      @each $breakpoint, $width in $breakpoints
      {
        $cols: map-get($sizes, $breakpoint);
        
        @if $cols != null
        {
          @media only screen and (min-width: $width) 
          {
            $current-left: 0;
            
            @for $i from 1 through length($cols) {
              $col: nth($cols, $i);
  
              $property: null; $value: null; $margin-left: null; $margin-right: null;
              
              // If the next column pushes over the boundy then reset flush to the left
              @if $current-left + $col > $total-columns {
                $current-left: 0;
              }
              
              @if $current-left % $total-columns == 0 { $margin-left: 0px; } @else { $margin-left: $gutter;  }
              
              $current-left: $current-left + $col;
              
              @if $current-left % $total-columns == 0 { $margin-right: 0px; } @else { $margin-right: $gutter; }
              
              // If the row is full then get ready for the next row
              @if $current-left == $total-columns {
                $current-left: 0;
              }
                          
              // Sum the unit widths plus the width of the gutters
              $width: ($unit-width * $col) + (($col - 1) * ($gutter * 2));
                         
              #{$sel} > *:nth-child(#{$i}) { width:$width; margin-right:$margin-right; margin-left:$margin-left; float:left; }
            }
          }
        }
      }
    }
  }

    @mixin invalid-state-icon {
      padding-right: 20px !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%233F37C9'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%233F37C9' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  
    @mixin valid-state-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  // Register the grids
  @include grids((
    ('.responsive-four-col-grid', (md:(3, 3, 3, 3), sm:(6, 6, 6, 6))),
    ('.responsive-nested-grid', (md:(4, 4, 4))),
    ('.two-col-grid', (sm:(3, 9))),
  ));
  

  @mixin aspect-ratio($width, $height) {
    position: relative;
    &:before{
        display: block;
        content: " ";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

  @mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

/* Define the mixin */
@mixin fluid-typography($minFont, $maxFont, $minBreakpoint, $maxBreakpoint) {

  /* Define variable for media query */
  $maxLessOne: $maxBreakpoint - 1;

  /* Define variable for fallback */
  $avg: ($maxFont + $minFont) / 2;

  /* Base font size */
  font-size: #{$minFont}px;

  @media (min-width: #{$minBreakpoint}px) and (max-width: #{$maxLessOne}px) {

    /* Adds a fallback for unsupported browsers */
    font-size: #{$avg}px;

    /* The fluid typography magic 🌟  */
    font-size: calc(#{$minFont}px + (#{$maxFont} - #{$minFont}) * (100vw - #{$minBreakpoint}px) / (#{$maxBreakpoint} - #{$minBreakpoint}))!important
  }

  @media (min-width: #{$maxBreakpoint}px) {
    font-size: #{$maxFont}px;
  }
}

/* Define the mixin */
@mixin fluid-property($property, $minFont, $maxFont, $minBreakpoint, $maxBreakpoint) {

  /* Define variable for media query */
  $maxLessOne: $maxBreakpoint - 1;

  /* Define variable for fallback */
  $avg: ($maxFont + $minFont) / 2;

  /* Base font size */
  #{$property}: #{$minFont}px;

  @media (min-width: #{$minBreakpoint}px) and (max-width: #{$maxLessOne}px) {

    /* Adds a fallback for unsupported browsers */
    #{$property}: #{$avg}px;

    /* The fluid typography magic 🌟  */
    #{$property}: calc(#{$minFont}px + (#{$maxFont} - #{$minFont}) * (100vw - #{$minBreakpoint}px) / (#{$maxBreakpoint} - #{$minBreakpoint}))
  }

  @media (min-width: #{$maxBreakpoint}px) {
    #{$property}: #{$maxFont}px;
  }
}

/* Border Radius
-------------------------*/

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

  // CONTAINER MIXIN

  @mixin min($bp, $max: "null", $device: "screen") {
    @if $max == "null" {
      @media only #{$device} and (min-width: #{$bp}) {
        @content;
      }
    } @else {
      @media only #{$device} and (min-width: #{$bp}) and (max-width: #{$max}) {
        @content;
      }
    }
  }
  
  @function bp($bp) {
    @return map-get($breakpoints, $bp);
  }

  @function container($container-size, $true-val: false) {
    @return map-get($container-sizes, $container-size);
  }
  
  $container-sizes: (
    sm: 100vw,
    md: 95vw,
    lg: 90vw,
    xl: 996px,
    xxl: 1050px,
    qhd: 1264px,
    _2k: 1264px,
  );
//   lg: $lg - 50px,
//   xl: $xl - 60px,
  .container {
    padding-right: 1rem;
    padding-left: 1rem;
  
    &:not(.is-fluid) {
      margin: 0 auto;
  
      @each $bp, $container-size in $container-sizes {
        @include min(#{bp(#{$bp})}) {
          width: 100%;
          max-width: container(#{$bp});
        }
      }
    }
  }
  
  @each $bp, $container-size in $container-sizes {
    .container-#{$bp} {
      margin: 0 auto;
      padding-right: 1rem;
      padding-left: 1rem;
      width: 100%;
  
      $i: index($container-sizes, $bp $container-size);
  
      @for $j from $i through length($container-sizes) {
        @include min(#{bp(nth(nth($container-sizes, $j), 1))}) {
          max-width: container(#{nth(nth($container-sizes, $j), 1)});
        }
      }
    }
  }


  

@mixin img-left-styles {
  padding-top: #{fluid(4px, 10px, 320px, 2600px)};
  padding-bottom: #{fluid(4px, 10px, 320px, 2600px)};
  padding-left: #{fluid(8px, 16px, 320px, 2600px)};
  padding-right: #{fluid(16px, 32px, 320px, 2600px)};
}

@mixin btn-img-right-styles {
  padding-top: #{fluid(4px, 10px, 320px, 2600px)};
  padding-bottom: #{fluid(4px, 10px, 320px, 2600px)};
  padding-left:  #{fluid(16px, 32px, 320px, 2600px)};
  padding-right: #{fluid(8px, 16px, 320px, 2600px)};
}