// @import url('src/assets/styles/loader.scss');
@import "mixins";
/* You can add global styles to this file, and also import other style files */
@import 'primeflex/core/variables';

@import "buttons";
@layer primeng;


//Primeng Reset classes
@layer reset {

  timesicon {
    // color: white;
  }

  .p-checkbox-box {
    opacity: 1 !important;
  }
  .p-datepicker-calendar-container .p-datepicker-calendar:last-child {

  }

  .p-radiobutton-checked .p-radiobutton-box {
    border-color: white !important;
    background-color: var(--deep-lavender) !important;
    outline: 2px solid var(--deep-lavender) !important;
  }

  .p-datepicker {
    @media only screen and (min-width: 1024px) {
      // margin-top: 0.5rem !important;
    }

    td,
    th {
      padding: 0.25rem !important;

      @media only screen and (max-width: 425px) {
        padding: 0.1rem !important;
      }
    }

    td span {
      // font-size: 12px !important;

      @media only screen and (max-width: 425px) {
        cursor: none !important;
        box-shadow: none !important;
      }
    }
  }

  .p-overlaypanel {
    margin-top: 18px !important;
    border-radius: 10px !important;

    &::before,
    &::after {
      left: calc(var(--overlayArrowLeft, 0) + 2.35rem) !important;

      @media only screen and (min-width: 1920px) {
        left: calc(var(--overlayArrowLeft, 0) + 1.25rem) !important;
      }
    }

    .p-overlaypanel-content {
      padding: 1rem 1rem !important;
    }
  }

  .p-select-overlay {
    width: 100%;
  }

  .p-select-option:not(.p-highlight):not(.p-disabled):hover {
    background: #f8f8f881 !important;
  }
  
  p-dropdown {
    .p-inputwrapper {
      .p-inputtext,
      .p-dropdown-trigger-icon {
        color: var(--pIconColor) !important;
        padding: 0 !important;
        font-size: 14px !important;
        align-items: center;
        display: flex;

        @media only screen and (max-width: 425px) {
          cursor: auto !important;
        }
      }

      .p-dropdown-empty-message {
        padding: 0.5rem 1rem;
        font-size: 14px;

        &:hover {
          background-color: #f3f4f6;
          border-radius: 5px;
        }
      }

      .p-dropdown-header {
        padding: 0.5rem 0.75rem !important;
        background: #ffffff !important;
      }

      .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
        background: #d9d9d981 !important;
      }

      .p-inputtext:nth-child(1) {
        font-size: 13px !important;
        color: rgb(107 114 128) !important;
      }

      .p-dropdown-trigger {
        justify-content: end !important;

        .p-dropdown-trigger-icon {
          width: 12px !important;
          height: 12px !important;
        }
      }
    }
  }

  .p-dropdown-panel {
    margin-top: 0.5rem;

    .p-dropdown-items {
      padding: 0.5rem;
      border-radius: 0.25rem !important;
      display: flex;
      flex-direction: column;
      row-gap: 0.25rem;

      .p-dropdown-item {
        padding: 0.5rem 1rem;

        @media only screen and (max-width: 435px) {
          cursor: auto !important;
        }

        &:hover {
          border-radius: 0.25rem !important;
        }

        .p-highlight,
        .p-focus {
          border-radius: 0.5rem !important;
        }

        span {
          font-weight: 500;
          font-size: 14px;
          // color: rgba(0, 0, 0, 0.7);
        }
      }

      .p-highlight,
      .p-focus {
        border-radius: 0.25rem !important;
      }
    }
  }

  .p-datatable-wrapper {
    border-radius: 0.5rem;
    border: 1px solid rgb(230, 230, 230);
    border-bottom: 0;

    .p-sortable-column {
      box-shadow: none;

      &:hover {
        background: #f9fafb !important;
      }
    }

    .p-sortable-column.p-highlight {
      background: #f9fafb !important;
      box-shadow: none !important;
    }

    .p-datatable-tbody > tr {
      cursor: pointer;

      &:not(.p-highlight):hover {
        background-color: #eaeaea54;
      }

      td {
        span {
          padding: 0.25rem 0.65rem;
        }
      }
    }

    .p-datatable-thead > tr > th {
      padding: 0.75rem !important;

      span {
        padding: 0.25rem 0.65rem;
        transition: all ease 0.3s;

        &:hover {
          background: #ffffffda;
          color: black !important;
          border-radius: 0.35rem;

          .p-sortable-column-icon {
            color: black !important;
          }
        }
      }
    }

    .p-sortable-column-icon {
      width: 11px !important;
      height: 11px !important;
      color: #6b7280 !important;
      transition: all ease 0.3s;
    }
  }

  .p-paginator-bottom {
    padding: 1rem 0;

    @media only screen and (max-width: 425px) {
      row-gap: 1rem;
    }

    .p-paginator-element {
      display: none !important;
    }
  }

  .p-menu-overlay {
    width: 8.5rem;
    padding: 0.5rem;
    z-index: 99999940 !important;

    .p-menu-list {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;

      .p-menuitem {
        .p-menuitem-content {
          border-radius: 0.25rem !important;
        }

        &:hover {
          background-color: transparent !important;
        }
      }

      .p-submenu-header {
        display: none !important;
      }
    }
  }

  .p-drawer-right {
    width: 26rem !important;

    @media only screen and (max-width: 425px) {
      width: 100% !important;
    }

    .p-drawer-header {
      align-items: start;
      margin-top: 0.5rem;
    }
  }

  .p-confirm-dialog {
    h3 {
      margin: 0;
    }

    .p-dialog-header {
      padding-bottom: 0;
    }

    p {
      margin: 0.75rem 1px;
      font-size: 15px;
    }

    .p-dialog-content {
      column-gap: 0.5rem;
    }

    @media only screen and (max-width: 425px) {
      margin: 1rem;
    }
  }

  .p-sidebar-mask {
    z-index: 10 !important;
  }

  .p-tooltip {
    font-size: 0.825rem !important;

    .p-tooltip-text {
      color: #f3f4f6;
      padding: 0.5rem 0.5rem !important;
    }

    .p-tooltip-arrow {
    }
  }

  
  .p-togglebutton.p-highlight, .p-togglebutton.p-togglebutton-checked {
    background-color: var(--deep-lavender);
    color: #ffffff;

    .pi {
      color: #ffffff;
    }
  }

  .p-togglebutton {
    padding: 0.55rem 1rem;
    color: var(--deep-lavender);
    border: 1px solid var(--deep-lavender);
  }

}
$xxl: 1400px;
$fhd: 1920px;
$qhd: 2560px;

$breakpoints: (
  'sm': $sm,
  'md': $md,
  'lg': $lg,
  'xl': $xl,
  'xxl': $xxl,
  'fhd': $fhd,
  'qhd': $qhd
);
@import 'primeflex/themes/primeone-light.scss';
@import 'primeflex/primeflex.scss';

:root {
  --dark: #15121e;
  --brownish-grey: #707070;
  --deep-lavender: #604997;
  --deep-lavender-dark: #7260D2;
  --white: #f5f5f5;
  --soft-pink: #f1b1be;
  --rosa: #ff89a1;
  --pale-orange: #ffa560;
  --soft-blue: #5191f7;
  --deep-lilac: #8978b9;
  --greyblue: #5acbcb;
  --peachy-pink: #fe8985;
  --pale-gold: #fdd46a;
  --bright-cyan: #4cbff5;
  --wheat: #f7de88;
  --very-light-pink: #d3d3d3;
  --light-grey-blue: #9fa9ba;
  --brownish-grey: #6c6c6c;
  --barbie-pink: #fe3f94;
  --aqua-marine: #43ddc4;
  --cornflower-light: #8186dd;
  --pale-violet: #c7b8f4;
  --light-salmon: #f7bf96;
  --light-cyan: #b3f7f7;
  --cornflower: #7575ff;
  --black: #2e2e2e;
  --white: #fff;
  --faded-pink: #e2abc2;
  --faded-pink: #e5acc1;
  --perrywinkle: #8d8ad9;
  --aqua-marine: #45d3d4;
  --aqua-marine: #45d6cf;
  --soft-blue: #61a1f7;
  --robin-s-egg-blue: #a8ebf7;
  --purpley: #634FCE;
  --dark: #0e0f21;
  --bluish-purple: #783fe5;
  --blueberry: #412c91;
  --manilla: #fff081;
  --sand-yellow: #f0d370;
  --brown-grey: #848484;
  --greyish-pink: #c98a97;
  --faded-orange: #ed995b;
  --tiffany-blue: #85d6d6;
  --medium-blue: #2965c6;
  --orange: #f0860c;
  --perrywinkle: #9e8adb;
  --sea: #459f9f;
  --pastel-red: #d86662;
  --cool-blue: #399bc9;
  --borderWidth: 6px;
  --borderRadius: 10px;
  --hue: 300;
  --hueOffset: 120;
  --level: 50%;
  --brightness: 75%;
  --gradient: radial-gradient(circle,
      hsl(var(--hue), 100%, var(--brightness)) 0%,
      hsl(calc(var(--hue) + var(--hueOffset)), 100%, var(--level)) 100%);
  --duration: 4s;
  --fraction: calc(var(--width) / (var(--width) + var(--height)));
  --c1: #a8efff;
  --c2: #331C81;
  --c3: #6F5BDB;
  --p-primary-color: var(--deep-lavender);
  --p-button-outlined-primary-border-color: var(--deep-lavender);
  --p-datepicker-date-width: 2rem !important;
  --p-datepicker-date-height: 2rem !important;
  --p-button-text-secondary-color: var(--deep-lavender);
}
$colors: (
  "dark": var(--dark),
  "brownish-grey": var(--brownish-grey),
  "deep-lavender": var(--deep-lavender),
  "deep-lavender-dark": var(--deep-lavender-dark),
  "white": var(--white),
  "soft-pink": var(--soft-pink),
  "rosa": var(--rosa),
  "purpley": var(--purpley),
  "pale-orange": var(--pale-orange),
  "soft-blue": var(--soft-blue),
  "deep-lilac": var(--deep-lilac),
  "greyblue": var(--greyblue),
  "peachy-pink": var(--peachy-pink),
  "pale-gold": var(--pale-gold),
  "bright-cyan": var(--bright-cyan),
  "wheat": var(--wheat),
  "very-light-pink": var(--very-light-pink),
  "light-grey-blue": var(--light-grey-blue),
  "barbie-pink": var(--barbie-pink),
  "aqua-marine": var(--aqua-marine),
  "cornflower-light": var(--cornflower-light),
  "pale-violet": var(--pale-violet),
  "light-salmon": var(--light-salmon),
  "light-cyan": var(--light-cyan),
  "cornflower": var(--cornflower),
  "black": var(--black),
  "faded-pink": var(--faded-pink),
  "perrywinkle": var(--perrywinkle),
  "bluish-purple": var(--bluish-purple),
  "blueberry": var(--blueberry),
  "manilla": var(--manilla),
  "sand-yellow": var(--sand-yellow),
  "brown-grey": var(--brown-grey),
  "greyish-pink": var(--greyish-pink),
  "faded-orange": var(--faded-orange),
  "tiffany-blue": var(--tiffany-blue),
  "medium-blue": var(--medium-blue),
  "orange": var(--orange),
  "sea": var(--sea),
  "pastel-red": var(--pastel-red),
  "cool-blue": var(--cool-blue)
);

// Apply each color to its respective class
@each $color, $value in $colors {
  .#{$color} {
    color: $value !important;
  }
}

// Example usage for background colors
@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: $value;
  }
}

/* Chrome, Edge, and Safari */
// ::-webkit-scrollbar {
//   width: 14px;
//   height: 14px;
// }
// ::-webkit-scrollbar-button {
//   width: 0px;
//   height: 0px;
// }

// *::-webkit-scrollbar-track {
//   background: #d2d2ff;
//   border-radius: 10px;
// }

// *::-webkit-scrollbar-thumb {

//   background: linear-gradient(var(--cornflower-light) 0%, var(--deep-lavender) 100%);
//   opacity: 0.12;
//   border-radius: 10px;
//   border: 1px none var(--cornflower)
// }

// ::-webkit-scrollbar-thumb:hover {
//   background: var(--deep-lavender-dark)
// }
// ::-webkit-scrollbar-thumb:active {
//   background: var(--deep-lilac)
// }

//* text sizes
$text-3xs: 0.5rem;
$text-2xs: 0.65rem;
$text-xs: 0.75rem;
$text-sm: 0.85rem;
$text-base: 1rem;
$text-lg: 1.1rem;
$text-xl: 1.2rem;
$text-2xl: 1.3rem;
$text-3xl: 1.4rem;
$text-4xl: 1.6rem;

$txt-btn-sm: 0.925rem;

.font-3xs {
  font-size: $text-3xs !important;
}

.font-2xs {
  font-size: $text-2xs !important;
}

.font-xs {
  font-size: $text-xs !important;
}

.font-sm {
  font-size: $text-sm !important;
}

.font-base {
  font-size: $text-base !important;
}

.font-lg {
  font-size: $text-lg !important;
}

.font-xl,
h4 {
  font-size: $text-xl !important;
}

.font-2xl {
  font-size: $text-2xl !important;
}

.font-3xl {
  font-size: $text-3xl !important;
}

.font-4xl {
  font-size: $text-4xl !important;
}

.font-btn-sm {
  font-size: $txt-btn-sm !important;
}

// Utility classes for fluid typography
.text-fluid-xsmall {
  font-size: #{fluid(12px, 16px, 320px, 2600px)};
}

.text-fluid-small {
  font-size: #{fluid(14px, 18px, 320px, 2600px)};
}

.text-fluid-medium {
  font-size: #{fluid(16px, 20px, 320px, 2600px)};
}

.text-fluid-large {
  font-size: #{fluid(18px, 24px, 320px, 2600px)};
}

.text-fluid-xlarge {
  font-size: #{fluid(20px, 28px, 320px, 2600px)};
}

.text-fluid-xxlarge {
  font-size: #{fluid(24px, 32px, 320px, 2600px)};
}

.text-fluid-xxxlarge {
  font-size: #{fluid(28px, 36px, 320px, 2600px)};
}

.text-fluid-caption {
  font-size: #{fluid(10px, 14px, 320px, 2600px)};
}

.text-fluid-display {
  font-size: #{fluid(32px, 48px, 320px, 2600px)};
}

.text-decoration-none {
  text-decoration: none;
}

// Utility class for headings
.heading-fluid {
  font-size: #{fluid(24px, 32px)};
}

*:-webkit-autofill,
*:-webkit-autofill:hover,
*:-webkit-autofill:focus,
*:-webkit-autofill:active {
  /* use animation hack, if you have hard styled input */
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
  /* text color */
  -webkit-text-fill-color: var(--black);
}

* {
  font-family: "Inter", "Arial", sans-serif;
}


body {
  margin: 0;
  color: #333;
  width: 100%;
  height: 100%;
  background-color: #F2F5FF;

  &.dark {
    background-color: #10132e;
  }
}

// for signin page
body.student-screen {
  --animation-speed: 30s;
  /* Add other specific styles for wide screens */
}

// for signin page
body.student-bg {
  background-image: url('assets/images/youngster-bg.webp');
  background-size: cover;
}

body.bg-parent {
  background-image: url('/assets/images/auth/parent-2.webp');
  background-position: 100% 10%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1b2947;
}

.register-parent {
  background-color: #10132e;
}

.btn-style {
  line-height: 32px;
  padding: 4px 18px;
}



.grad-intro-bg {
  background-image: linear-gradient(90deg,
      hsl(249deg 64% 61%) 0%,
      hsl(248deg 66% 64%) 11%,
      hsl(246deg 69% 67%) 22%,
      hsl(245deg 71% 70%) 33%,
      hsl(243deg 74% 73%) 44%,
      hsl(242deg 78% 75%) 56%,
      hsl(241deg 82% 78%) 67%,
      hsl(239deg 87% 81%) 78%,
      hsl(238deg 93% 83%) 89%,
      hsl(237deg 100% 85%) 100%);
}

.no-bg-btn {
  border-color: transparent;
  background-color: transparent;
  color: var(--deep-lavender) !important;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.buy-package-grad-btn {
  border-color: transparent;
  background-image: linear-gradient(270deg,
      hsl(339deg 76% 52%) 0%,
      hsl(332deg 79% 50%) 11%,
      hsl(326deg 86% 47%) 22%,
      hsl(320deg 88% 46%) 33%,
      hsl(314deg 80% 46%) 44%,
      hsl(305deg 69% 46%) 56%,
      hsl(292deg 61% 50%) 67%,
      hsl(277deg 71% 56%) 78%,
      hsl(261deg 84% 62%) 89%,
      hsl(239deg 100% 67%) 100%);
  color: #fff !important;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.trial-btn {
  border-color: transparent;
  background-color: #f6a33d;
  background-size: 300% 100%;
  border-radius: 50px;
  color: var(--surface-900) !important;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.primary-purple-color,
.text-primary {
  color: var(--deep-lavender) !important;
}

.primary-purple-color-bg {
  background-color: var(--deep-lavender);

  &.darker {
    background-color: var(--deep-lavender-dark) !important;
  }
}

.purple-border {
  border: 1px solid var(--deep-lavender);
}

p-checkbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
  border-color: #e24c4c !important;
}

.p-inline-message.p-inline-message-warning {
  background: rgba(255, 242, 226, 0.7);
  border: solid #cc8925;
  border-width: 0px;
  color: #cc8925;
}

.p-inline-message.p-inline-message-danger {
  background: rgba(255, 231, 230, 0.7);
  border: solid #ff5757;
  border-width: 0px;
  color: #ff5757;
}

.expiring-bg {
  background: linear-gradient(#E46881, #FC8785) padding-box,
    linear-gradient(to right, #E46881, #FC8785) border-box;
}


.mini-flag {
  width: 18px;
  height: 18px;
}


.add-to-cart {
  // background: linear-gradient(#8186dd 0%, #7762f6 100%);
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
}


.primary-blue {
  &.p-checkbox {}

  &.p-checkbox-label {
    cursor: pointer;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  &.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
    top: 1px;
    left: -5px;
  }

  &.p-checkbox .p-checkbox-box {
    border-color: var(--deep-lavender);
  }

  &.p-checkbox .p-checkbox-box.p-highlight {
    background-color: transparent;
    border-color: var(--deep-lavender);
  }

  &.bigger {
    &.p-checkbox {
      min-width: 24px;
      min-height: 24px;
    }
  }

  svg path {}

  &.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transform: scale(1.6);
    // color: var(--deep-lavender);
    stroke-width: 15;
    /*Between 1px and 2px*/
    letter-spacing: 1px;

    & svg path {
      stroke: #ffffff;
      /* Background color against your svg */
      stroke-width: 1px;
      /*Between 1px and 2px*/
    }
  }
}

.fly img {
  animation: fly-back 1s forwards;
}

.fly:hover img {
  animation: fly 8s infinite;
}

@keyframes fly {
  0% {
    transform: scale(1) translateX(0) translateY(0);
  }

  50% {
    transform: scale(0.7) translateX(50%) translateY(-50%);
  }

  to {
    transform: scale(1) translateX(-50%) translateY(50%);
  }
}

@keyframes fly-back {
  0% {
    transform: scale(1) translateX(30%) translateY(-30%);
  }

  to {
    transform: scale(1) translateX(0) translateY(0);
  }
}

.height-100 {
  height: calc(100vh - 70px) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-image-none {
  background-image: none !important;
}

.border-purple {
  border-color: var(--deep-lavender) !important;
}

.bg-purple {
  background: var(--deep-lavender) !important;
}

.timezone-dropdown .p-dropdown-item {
    font-size: 0.8rem;
    white-space: normal;
    padding: 0.5rem 0.5rem;
}

.button-badge-right {
  position: absolute;
  right: 0;
  top: -7px;
  min-width: 1.3rem;
  height: 1.3rem;
  display: FLEX;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}

.dark-purple-bg-btn {
  background-color: #676CD1;
  border: 1px solid #676CD1;
}

.blue-gradient-btn {
  background-image: linear-gradient(180deg,
      hsl(214deg 94% 75%) 0%,
      hsl(216deg 91% 73%) 11%,
      hsl(217deg 88% 72%) 22%,
      hsl(218deg 85% 70%) 33%,
      hsl(220deg 82% 69%) 44%,
      hsl(221deg 79% 68%) 56%,
      hsl(223deg 76% 66%) 67%,
      hsl(225deg 73% 65%) 78%,
      hsl(228deg 69% 64%) 89%,
      hsl(231deg 66% 63%) 100%);
}

.join-lesson-btn {
  background-image: linear-gradient(270deg, hsl(245, 57%, 61%) 0%, hsl(245, 56%, 59%) 11%, hsl(246, 54%, 58%) 22%, hsl(247, 53%, 57%) 33%, hsl(248, 52%, 56%) 44%, hsl(249, 51%, 55%) 56%, hsl(250, 49%, 53%) 67%, hsl(250, 48%, 52%) 78%, hsl(251, 47%, 51%) 89%, hsl(252, 47%, 50%) 100%);
  color: white !important;

  &.disabled {
    opacity: 0.6;
  }
}

.cancel-bg-btn {
  background-image: linear-gradient(180deg,
      hsl(24deg 86% 78%) 0%,
      hsl(22deg 88% 77%) 11%,
      hsl(20deg 90% 77%) 22%,
      hsl(18deg 92% 76%) 33%,
      hsl(16deg 94% 76%) 44%,
      hsl(13deg 95% 76%) 56%,
      hsl(11deg 97% 76%) 67%,
      hsl(8deg 97% 76%) 78%,
      hsl(6deg 98% 76%) 89%,
      hsl(2deg 98% 76%) 100%);
  border-color: transparent;
}

.warn-bg-btn {
  background-color: #FE8985;
  border: 1px solid #FE8985;
}

.purple-grad1-btn  button {
  background: var(--primary-gradient, -webkit-linear-gradient(35deg, #6556fa, #e31e73));
}

.azure-bg-btn {
  background-color: #46d1d6;
  border: 1px solid #46d1d6;
  
  

  &.img-left {
    @include img-left-styles; // Include styles from the mixin
  }
}

.img-left {
  @include img-left-styles; // Include styles from the mixin
}

.submit-bg-border {
  border: 1px solid var(--deep-lavender);
}

.submit-bg-color {
  color: var(--deep-lavender);
}


.azure-bg-border {
  border: 1px solid #46d1d6;
}

.azure-bg-color {
  color: #46d1d6;
}

.azure-bg button {
  background-color: #46d1d6;
  border: none;
  width: auto;
  justify-content: center;

  &:hover {
    background-color: var(--cool-blue)
  }

  .p-button-label {
    flex: unset;
  }
}

.purple-grad-bg.gradient button {
  background-image: linear-gradient(
    90deg,
    hsl(339deg 76% 52%) 0%,
    hsl(334deg 78% 50%) 8%,
    hsl(329deg 83% 48%) 17%,
    hsl(324deg 88% 47%) 25%,
    hsl(320deg 88% 46%) 33%,
    hsl(315deg 82% 46%) 42%,
    hsl(310deg 75% 46%) 50%,
    hsl(302deg 66% 46%) 58%,
    hsl(292deg 61% 50%) 67%,
    hsl(281deg 68% 55%) 75%,
    hsl(269deg 77% 59%) 83%,
    hsl(256deg 88% 63%) 92%,
    hsl(239deg 100% 67%) 100%
  );
}

.azure-bg.gradient button {
  border-radius: 10px;
  background-image: linear-gradient(180deg,
      hsl(179deg 63% 55%) 0%,
      hsl(181deg 65% 55%) 8%,
      hsl(183deg 69% 54%) 15%,
      hsl(184deg 72% 54%) 23%,
      hsl(186deg 75% 53%) 31%,
      hsl(187deg 77% 53%) 38%,
      hsl(189deg 80% 54%) 46%,
      hsl(190deg 82% 54%) 54%,
      hsl(191deg 84% 55%) 62%,
      hsl(193deg 85% 57%) 69%,
      hsl(194deg 87% 58%) 77%,
      hsl(196deg 88% 60%) 85%,
      hsl(197deg 89% 61%) 92%,
      hsl(199deg 89% 63%) 100%);

  &:hover {
    filter: brightness(110%);
    box-shadow: 0 0 2px 1px hsl(194deg 87% 58%);
  }
}

button {
  &:hover {
    filter: brightness(110%);
  }
}

.avatar-circle {
  border-radius: 50px;
  background: linear-gradient(#8186dd 0%, #755fb0 100%);

  img {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    padding: 2px;
  }

  &.smaller {
    img {
      width: 24px;
      height: 24px;
    }
  }
}


.container-stars {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:not(.no-bg) {

    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);

    &.light-bottom {

      background: radial-gradient(ellipse at bottom, #527B93 0%, #090a0f 100%);
    }
  }
}

.grad-purple-1 {
  background-image: linear-gradient(180deg,
  hsl(241deg 54% 67%) 0%,
  hsl(242deg 54% 66%) 11%,
  hsl(244deg 54% 65%) 22%,
  hsl(246deg 54% 64%) 33%,
  hsl(247deg 53% 63%) 44%,
  hsl(249deg 53% 61%) 56%,
  hsl(251deg 52% 60%) 67%,
  hsl(253deg 52% 59%) 78%,
  hsl(254deg 51% 57%) 89%,
  hsl(256deg 51% 56%) 100%);
}

.gradient-purple-btn {
  button {
    border: 1px solid var(--deep-lavender);
    background-color: var(--deep-lavender);
    // background-image: linear-gradient(180deg,
    //     hsl(241deg 54% 67%) 0%,
    //     hsl(242deg 54% 66%) 11%,
    //     hsl(244deg 54% 65%) 22%,
    //     hsl(246deg 54% 64%) 33%,
    //     hsl(247deg 53% 63%) 44%,
    //     hsl(249deg 53% 61%) 56%,
    //     hsl(251deg 52% 60%) 67%,
    //     hsl(253deg 52% 59%) 78%,
    //     hsl(254deg 51% 57%) 89%,
    //     hsl(256deg 51% 56%) 100%);

    background-size: 300% 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &:hover {
      background-image: none;
      background-position: 100% 0;
      box-shadow: 0 1px 5px 0 rgba(1, 1, 1, 0.75);
      moz-transition: all 0.4s ease-in-out;
      color: #fff;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
  }

}

.form__box {
  border: 1.2px solid #ffffff;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

.more-textarea {
  // border: 1px solid var(--deep-lavender-dark);
  border-radius: 10px;
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
  color: var(--deep-lavender-dark);
  
  &::-webkit-input-placeholder {
    // color: var(--deep-lavender);
    opacity: 0.8;
    text-align: left;
  }
}

.simple-form-input {
  -webkit-text-fill-color: #333;
  background-color: var(--white);
  border: 1px solid #CACACA;
  box-sizing: border-box;
  font-size: 1rem;
  margin: 0;
  outline: none;
  user-select: none;
  width: 100%;
  height: 2.6rem;
  text-align: center;
  line-height: 1;

  &.rounded {
    border-radius: 50px;
  }

  &::-webkit-input-placeholder {
    color: var(--deep-lavender);
    opacity: 0.8;
    text-align: center;
  }

  &.purple-border {
    border: 1px solid var(--deep-lavender);

    &::-webkit-input-placeholder {
      color: var(--deep-lavender);
      opacity: 0.8;
      text-align: left;
    }
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.sm {
    height: 2rem;

    .p-dropdown-trigger {
      width: 2rem;
    }

    .p-inputtext {
      font-size: 0.8rem;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.simple-form-input.webkit-purple,
.webkit-purple {
  -webkit-text-fill-color: var(--deep-lavender) !important;
  font-weight: 500;

  &::-webkit-input-placeholder {
    color: var(--deep-lavender);
    opacity: 0.8;
    text-align: center;
  }
}

.border-4 {
  border-radius: 0.25rem;
}

.container-960-max {
  max-width: 1200px;
  margin: 0 auto;
}

.container-1200-max {
  max-width: 1200px;
  margin: 0 auto;
}

.color-c2 {
  color: var(--c2);
}

.cyan-btn {
  color: white;
  border: none;
  background-color: #46d1d6;
}

.submit-btn {
  color: white;
  border: none;
  // border-radius: 50px;
  // padding: 0.5rem 1rem 0.5rem 1rem;
  background: linear-gradient(#c7b8f4 0%, var(--deep-lavender) 100%);
  background-image: linear-gradient(to right,
      var(--deep-lavender-dark),
      var(--deep-lavender-dark),
      var(--deep-lavender-dark),
      var(--bluish-purple));
  background-size: 300% 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  &.inverted {
    background: #fff;
    background-image: #fff;
    background-position: 100% 0;
    // box-shadow: 0 1px 5px 0 rgba(1, 1, 1, 0.75);
    moz-transition: all 0.4s ease-in-out;
    color: var(--deep-lavender-dark);
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  &.img-left {
    @include img-left-styles; // Include styles from the mixin
  }

  &.img-right {
    @include btn-img-right-styles;
  }

  &:hover {
    background-position: 100% 0;
    box-shadow: 0 1px 5px 0 rgba(1, 1, 1, 0.75);
    moz-transition: all 0.4s ease-in-out;
    color: #fff;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  &:active {
    background-position: 100% 0;
    background-image: linear-gradient(to right,
        var(--deep-lavender),
        var(--deep-lavender),
        var(--deep-lavender),
        var(--deep-lilac));
    moz-transition: all 0.4s ease-in-out;
    color: #fff;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lg-scroll::-webkit-scrollbar-track {
  border: 1px solid #fff;
  background-color: var(--very-light-pink) !important;
}

.lg-scroll::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.lg-scroll::-webkit-scrollbar-thumb {
  background-color: var(--pale-violet) !important;
}


/*
  *  STYLE 9
  */

.p-scrollpanel.custombar1,
.p-scrollpanel.customsidebar-scroll {

  .p-scrollpanel-content {
    width: calc(100% + 20px);
    padding: 2px 20px 13px 0;
  }
  .p-scrollpanel-bar {
    background-color: var(--pale-violet) !important;
    opacity: 1 !important;
    transition: background-color 0.2s;
  }
}

.inline-loader {
  width: 60px
}

.inline-loader,
.loader-box {
  display: inline-block;
  height: 10px
}

.loader-box {
  animation-direction: alternate;
  animation-duration: .5s;
  animation-iteration-count: infinite;
  animation-name: fadeOutIn;
  background-color: #8186dd;
  margin: 0 4px;
  border-radius: 5px;
  width: 10px;
}

.loader-box:first-child {
  animation-delay: .25s
}

.loader-box:nth-child(2) {
  animation-delay: .5s
}

.loader-box:nth-child(3) {
  animation-delay: .75s
}

@keyframes fadeOutIn {
  0% {
    background-color: #8186dd
  }

  to {
    background-color: rgba(0, 146, 255, 0)
  }
}


.p-inputtext, .p-button, .p-dropdown, .p-multiselect {
  border-radius: 0.25rem;
  line-height: 1.375;
}

.p-inputtext:enabled:hover, .p-dropdown:not(.p-disabled):hover, .p-multiselect:not(.p-disabled):hover {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--pale-violet);
  border-color: var(--deep-lavender);
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #C7D2FE;
  border-color: var(--deep-lavender);
}

.p-inputtext.ng-dirty.ng-invalid,
.simple-form-input.ng-dirty.ng-invalid {
  border: 1px solid red;
}

p-dropdown.ng-dirty.ng-invalid>.p-dropdown {
  border: 1px solid red;
}

.saturate {
  filter: saturate(3);
}

.grayscale {
  filter: grayscale(100%);
}

.contrast {
  filter: contrast(160%);
}

.brightness {
  filter: brightness(0.25);
}

.blur {
  filter: blur(3px);
}

.invert {
  filter: invert(100%);
}

.sepia {
  filter: sepia(100%);
}

.huerotate {
  filter: hue-rotate(180deg);
}

.rss.opacity {
  filter: opacity(50%);
}

.p-carousel-content {
  position: relative;
  overflow: unset;
  align-items: center;
    justify-content: CENTER;
}

.p-carousel .p-carousel-content .p-carousel-prev-button,
.p-carousel .p-carousel-content .p-carousel-next-button {
  z-index: 555555;
  .p-button {
  }
}



.writing {
  background-image: linear-gradient(180deg,
      hsl(22deg 88% 78%) 0%,
      hsl(21deg 89% 77%) 11%,
      hsl(19deg 90% 77%) 22%,
      hsl(18deg 91% 77%) 33%,
      hsl(16deg 92% 77%) 44%,
      hsl(15deg 93% 77%) 56%,
      hsl(13deg 94% 76%) 67%,
      hsl(11deg 94% 76%) 78%,
      hsl(9deg 95% 76%) 89%,
      hsl(7deg 95% 76%) 100%) !important;
  ;
}

.listening {
  background-image: linear-gradient(180deg,
      hsl(340deg 53% 78%) 0%,
      hsl(335deg 48% 75%) 11%,
      hsl(329deg 43% 72%) 22%,
      hsl(323deg 38% 69%) 33%,
      hsl(316deg 34% 65%) 44%,
      hsl(307deg 29% 62%) 56%,
      hsl(296deg 27% 60%) 67%,
      hsl(285deg 29% 58%) 78%,
      hsl(275deg 30% 57%) 89%,
      hsl(265deg 31% 56%) 100%) !important;
  ;
}

.reading {
  background-image: linear-gradient(180deg,
      hsl(255deg 73% 84%) 0%,
      hsl(253deg 70% 82%) 11%,
      hsl(252deg 67% 79%) 22%,
      hsl(250deg 65% 77%) 33%,
      hsl(248deg 63% 75%) 44%,
      hsl(247deg 61% 73%) 56%,
      hsl(245deg 60% 71%) 67%,
      hsl(242deg 59% 68%) 78%,
      hsl(240deg 58% 66%) 89%,
      hsl(237deg 58% 63%) 100%) !important;
  ;
}

.speaking {
  background-image: linear-gradient(180deg,
      hsl(172deg 68% 56%) 0%,
      hsl(176deg 69% 53%) 11%,
      hsl(180deg 75% 48%) 22%,
      hsl(183deg 95% 45%) 33%,
      hsl(185deg 100% 45%) 44%,
      hsl(187deg 100% 46%) 56%,
      hsl(190deg 87% 50%) 67%,
      hsl(193deg 88% 55%) 78%,
      hsl(196deg 89% 59%) 89%,
      hsl(199deg 89% 63%) 100%) !important;
  ;
}

.motivation {
  background-image: linear-gradient(180deg,
      hsl(185deg 82% 82%) 0%,
      hsl(189deg 85% 79%) 11%,
      hsl(192deg 88% 76%) 22%,
      hsl(194deg 90% 74%) 33%,
      hsl(197deg 93% 71%) 44%,
      hsl(199deg 94% 69%) 56%,
      hsl(202deg 95% 68%) 67%,
      hsl(205deg 95% 68%) 78%,
      hsl(209deg 93% 68%) 89%,
      hsl(213deg 90% 68%) 100%) !important;
  ;
}

.attentiveness {
  background-image: linear-gradient(180deg,
      hsl(42deg 96% 69%) 0%,
      hsl(42deg 96% 69%) 8%,
      hsl(41deg 95% 68%) 15%,
      hsl(40deg 95% 67%) 23%,
      hsl(40deg 94% 66%) 31%,
      hsl(39deg 94% 66%) 38%,
      hsl(38deg 93% 65%) 46%,
      hsl(37deg 93% 64%) 54%,
      hsl(37deg 92% 63%) 62%,
      hsl(36deg 92% 63%) 69%,
      hsl(35deg 91% 62%) 77%,
      hsl(35deg 91% 61%) 85%,
      hsl(34deg 91% 61%) 92%,
      hsl(33deg 90% 60%) 100%) !important;
}


.search-input-purple {
  border-radius: 18.2px;
  padding: 1rem;
  border: 1px solid transparent;
  background-color: #e2def8;
  color: var(--deep-lavender);

  &.bordered {
    background-color: transparent;
    border: 1px solid transparent;
    border: 1px solid var(--deep-lavender);
  }

  &::-webkit-input-placeholder {
    color: var(--deep-lavender);
    opacity: 0.8;
    text-align: left;
  }
}

.primary-purple-dropdown {
  &.p-checkbox {}

  &.p-checkbox-label {
    cursor: pointer;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  &.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
    top: 1px;
    left: -5px;
  }

  &.p-checkbox .p-checkbox-box {
    border-color: #46d1d6;
    width: 18px;
    height: 18px;
  }

  &.p-checkbox .p-checkbox-box.p-highlight {
    background-color: #46d1d6;
    border-color: #46d1d6;
  }

  &.bigger {
    &.p-checkbox {
      min-width: 24px;
      min-height: 24px;
    }
  }

  svg path {}

  &.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transform: scale(1.2);
    color: #ffffff;
    stroke-width: 15;
    /*Between 1px and 2px*/
    letter-spacing: 1px;

    & svg path {
      stroke: #ffffff;
      /* Background color against your svg */
      stroke-width: 1px;
      /*Between 1px and 2px*/
    }
  }
}


.layout-wrapper.layout-static-inactive .layout-sidebar {
  transform: translateX(0%);
  transition: all 0.1s;
  width: 4rem;
  left: 0;
  &.full_shown {
    transform: none;
  }
}

.primary-azure-radio {
  &.p-radiobutton .p-radiobutton-box {
    border-color: #46d1d6;

    .p-radiobutton-icon {
      background-color: #46d1d6;
    }

    &.p-highlight {
      background-color: #fff;
    }
  }
}

.checkout__right-fixed {
  display: none;

  @include breakpoint(xxlarge) {
    display: block;
  }
}

.checkout-wrapper .layout-main-container {
  // margin-bottom: 8rem;
  width: 100%;
  margin-right: 0;

  @include breakpoint(xxlarge) {
    padding-right: 320px;
  }
}

@include breakpoint(large) {

  .layout-sidebar {
    transform: translateX(0);
  }

  .blocked-scroll {
    overflow: hidden;
  }

  .checkout-wrapper.layout-static .layout-main-container {
    margin-left: 300px;
  }

}

.back-btn-popup {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 18px;
  transform: translateY(-50%);
}

.p-stepper .p-stepper-panels {
  padding: 0 !important;
}

.bottom-bar-fixed {
  z-index: 10;
  background-color: #eef1ff;
}

@media print {
  body * {
      visibility: hidden;
  }

  .layout-invoice-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      * {
          visibility: visible;
          box-shadow: none;
          color: #212121 !important;
          border-color: #e4e4e4 !important;
          background-color: transparent;
      }
  }
}


.indiv_preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: transparent;
}

.preloader .preloader-spin {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1001;
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: var(--cornflower);
  -webkit-animation: PreloaderSpin 2s linear infinite;
  animation: PreloaderSpin 2s linear infinite;
  display: block;
  border-top-color: var(--deep-lavender-dark);
}

.preloader-spin:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: var(--cornflower);
  -webkit-animation: PreloaderSpin 3s linear infinite;
  animation: PreloaderSpin 3s linear infinite;
  border-top-color: var(--bluish-purple);
}

.preloader-spin:after{
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #a3e7f0;
  -webkit-animation: PreloaderSpin 1.5s linear infinite;
  animation: PreloaderSpin 1.5s linear infinite;
  border-top-color: var(--deep-lavender);
}

.preloader{
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index:999999;
  opacity:1;
}

@-webkit-keyframes PreloaderSpin{
  0%{
    -webkit-transform:rotate(0deg);
    -ms-transform:rotate(0deg);
    transform:rotate(0deg)
  }
  100%{
    -webkit-transform:rotate(360deg);
    -ms-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}
@keyframes PreloaderSpin{
  0%{
    -webkit-transform:rotate(0deg);
    -ms-transform:rotate(0deg);
    transform:rotate(0deg)
  }
  100%{
    -webkit-transform:rotate(360deg);
    -ms-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}

